import React, { Component } from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import mixpanel from 'mixpanel-browser'
import { Link, graphql } from 'gatsby'
const queryString = require('query-string')
import Layout from '../../components/Layout'
import Button from '../../components/marketplace/common/Button'

class SweepstakesPage extends Component {
  constructor() {
    super()
    this.state = {
      surveyId: '', // id of survey with no questions
      prizeId: '',
      base: '',
      externalSearchUrl: '',
    }
  }

  componentDidMount() {
    const { survey, rwd, ref } = queryString.parse(location.search)
    const { hostname } = window.location
    const prod = hostname !== 'localhost'
    const base = prod ? 'https://app.collegepulse.com' : 'http://localhost:3000'

    const justDemsSurvey = prod ? '' : '5d1bc7a878e487abb8f8a2b8'
    const surveyId = survey || justDemsSurvey
    this.setState({ base, surveyId, ref })

    if (window.location.hostname === 'localhost') {
      mixpanel.init('ddf52ed93dbdca563d9e41cb72f87dd6')
    } else {
      mixpanel.init('6f24e5d7e84c4e367451d45bd7123131')
      mixpanel.set_config({ disable_notifications: true })
    }

    mixpanel.track('Sweepstakes Intro Page Loaded', { survey: surveyId })
  }

  render() {
    const externalSurveyUrl = `${this.state.base}/external-survey/${this.state.surveyId}?rwd=${
      this.state.prizeId
    }&ref=${this.state.ref || 'sweepstakes'}`
    return (
      <Layout hideMarketplace>
        <div className="content">
          <div className="full-width-image tall margin-top-0 mobile-responsive-banner">
            <div
              className="content"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h2
                className="has-text-weight-bold is-size-1-tablet is-size-2-mobile"
                style={{
                  backgroundColor: 'white',
                  color: '#2B3E51',
                  padding: '1rem',
                }}
              >
                $2,000 Amazon Gift Card Sweepstakes
              </h2>
              <p className="has-text-white">
                Take a short survey and be entered for a chance to win a $2,000 Amazon gift card.
                Over 200,000 college students have signed up for College Pulse to have data driven
                discussions with others on campus and across the US, and earn points doing it.
              </p>
              <p className="has-text-white has-text-weight-bold">
                Deadline: 10pm PT November 30, 2019
              </p>
              <a href={externalSurveyUrl}>
                <Button
                  text="Start Survey"
                  width={200}
                  customStyle={{
                    color: 'white',
                    backgroundColor: '#2185d0',
                    border: 'none',
                    boxShadow: '0px 0px 3px rgba(0,0,0,.2)',
                  }}
                />
              </a>
              <p className="has-text-light" style={{ fontSize: 12 }}>
                By starting this survey, you acknowledge and agree to our{' '}
                <a className="has-text-light" href="https://collegepulse.com/privacy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a className="has-text-light" href="https://collegepulse.com/terms-of-service">
                  Terms of Use
                </a>{' '}
                and{' '}
                <a className="has-text-light" href="https://collegepulse.com/terms-of-service">
                  sweepstakes rules
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-4">Deadline</h3>
                <p>November 30, 2019</p>
                <p>The winner will be contacted automatically</p>
                <h3 className="has-text-weight-semibold is-size-4" style={{ marginTop: 10 }}>
                  Who Can Apply
                </h3>
                <p>Open to active college students with a valid .edu email</p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SweepstakesPage
