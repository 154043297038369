import React from 'react'

export default ({ text, onClick, width, height, customStyle = {}, className }) => (
  <div
    onClick={onClick}
    className={`productButton ${className || ''}`}
    style={{ width, height, ...customStyle }}
  >
    <p>{text}</p>
  </div>
)
